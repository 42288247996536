/* MUI Tabls */
.tabs-root {
  color: white !important;
  background-color: #4c7bfc;
  height: 56px;
}

.tab-indicator-color {
  background-color: #4c7bfc !important;
}

.tab-root {
  color: #1976d2 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.75 !important;
  letter-spacing: 0.5px !important;
}

.tab-root-disabled {
  color: rgba(255, 255, 255, 0.38) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.75 !important;
  letter-spacing: 0.5px !important;
}

.tab-selected > span:first-child {
  width: unset;
  background: unset !important;
  background-color: rgba(255, 255, 255, 0.87);
  color: #4c7bfc !important;
  border-radius: 8px;
  min-width: 158px;
}

.MuiTab-root.Mui-selected {
  color: #1976d2 !important;
  background-color: unset !important;
  border-radius: 8px;
  margin: 6px;
  height: 70px !important;
  max-height: unset !important;
}
