.map-completed {
    fill: #6EAA2C;
}

.map-ongoing {
    fill: #F57E23;
}

.map-incomplete {
    fill: #E0E0E0;
}

.map-county-training {
    fill: #DB60CE;
}

.map-not-participating {
    fill: #007499;
}

.map-region-1 {
    fill: lightgreen;
}

.map-region-2 {
    fill: lightsalmon;
}

.map-region-3 {
    fill: lightpink;
}

.map-region-4 {
    fill: yellow;
}

.map-region-6 {
    fill: lightseagreen;
}

.map-region-default {
    fill: #E0E0E0;
}

.map-content {
    position: relative;
}

.map-content path:hover {
    cursor: pointer;
}

.map-hover {
    stroke: #FFFF;
    fill: #1976d2;
    stroke-width: 1.5;
}

.map-hover-text {
    fill: #FFFF;
}
